import React from "react";
import {
    MuiThemeProvider,
    createMuiTheme
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  typography: {
      useNextVariants: true
  },
  palette: {
    /*
    primary: {
      main: "#455a64",
      light: "#718792",
      dark: "#1c313a",
      contrastText: "#ffffff",
    },
    */
    secondary: {
      main: "#bdbdbd",
      contrastText: "#000000",
    },
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
    <MuiThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Component {...props} />
    </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
