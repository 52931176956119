
import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import {
  BrowserView,
  MobileView
} from "react-device-detect";
import SideDrawer from "./SideDrawer";
import {
    AppBar,
    Button,
    Toolbar,
    Menu,
    MenuItem
} from "@material-ui/core";
import Logo from '../assets/logo.png'
import '../styles/App.css';

// MSAL imports
import { useMsal } from "@azure/msal-react";

function NavBar(props) {

  const [menuAnchor, setMenuAnchor] = useState(null);
  // fetch our Public Client Application instance
  const {instance} = useMsal();

  const handleClickLogout = async () => {
    setMenuAnchor(null);
    
    
    // Insert Active Account into paramters
    instance.logoutRedirect();
    //await Auth.signOut();
    //props.history.push("/");
  };

  const handleClickGuide = async () => {
    setMenuAnchor(null);

    props.history.push("/guide");
  };

  const handleClickHomepage = async () => {
    setMenuAnchor(null);

    props.history.push("/");
  };

  const handleClickOpenMenu = event => {
    setMenuAnchor(event.currentTarget);
  };


  const handleClickCloseMenu = () => {
    setMenuAnchor(null);
  };
  
  return (
    <div>
    <BrowserView>
      <AppBar position="fixed" color="default" className={props.className}>
        <Toolbar>
          <SideDrawer style="icon"/>
            <img className="navBarLogo" alt="Logo" onClick={()=>{props.history.push("/")}} src={Logo} height={"100%"}/>
            <p className="appBarTitle">
              | Translate
            </p>

          {/* <SideDrawer style="text"/>
          <Button color="primary" onClick={handleClickGuide}>
            Guide
          </Button>
          <Button color="primary" onClick={handleClickLogout}>
            Logout
          </Button> */}
        </Toolbar>
      </AppBar>
    </BrowserView>
    <MobileView>
      <AppBar position="fixed" color="default" className={props.className}>
        <Toolbar>
          <img className="navBarLogo" alt="Logo" onClick={()=>{props.history.push("/")}} src={Logo} height={"100%"}/>
          <p className="appBarTitle">
            | Translate
          </p>
          <Button color="primary" onClick={handleClickOpenMenu}>
            Menu
          </Button>
          <Menu
            id="menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleClickCloseMenu}
          >
            <MenuItem onClick={handleClickHomepage}>Menu</MenuItem>
            <MenuItem onClick={handleClickGuide}>Guide</MenuItem>
            <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </MobileView>
    </div>
  );
}

export default withRouter(NavBar);