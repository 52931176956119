import React from "react";
import Lottie from 'react-lottie';
import '../styles/App.css';
import {
    Grid,
    Button
} from "@material-ui/core";
import aniData from "../data/ani-globe.json";

export default function Landing({handleSignIn}) {
  
  const aniOptions = {
    loop: false,
    autoplay: true,
    animationData: aniData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  
  return (
    <div>
      <Grid container spacing={0} className="landingGrid">
        <Grid item xs={12}>
          <Lottie
            options={aniOptions}
            height={350}
            width={350}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="landingDescription">Welcome to Lilly Translate, home to all your language service needs!</p>
          <Button variant="contained" color="primary" onClick={handleSignIn}>
            Login
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
