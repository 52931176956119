import * as React from 'react';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
    AppBar,
    Button,
    Toolbar
} from "@material-ui/core";
import '../styles/App.css';
import Logo from '../assets/logo.png'
import ErrorBoundary from "../components/ErrorBoundary";
import Landing from "./Landing";

// MSAL imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

function CustomFederatedSignIn() {
  
  // fetch our Public Client Application instance
  const {instance} = useMsal();

  const handleSignIn = () => {
    
    try {
      instance.loginRedirect(loginRequest);
    } 
    catch (err) {
      console.error(err);
    }
  }

  return (
    <ErrorBoundary>
      <div className="appContainer">
        <div className="contentContainer">
          <Switch>
            <Route exact path="/" render={() => <Landing handleSignIn={handleSignIn} />} />{" "}
            <Route path="*" render={() => <Redirect to="/" />} />{" "}
          </Switch>{" "}
        </div>{" "}
      </div>
    </ErrorBoundary>
  );
}


export default withRouter(CustomFederatedSignIn);