import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";

// *************************************************************************
// Overall Settings

const modalTitle = "Terms of Use";


export default function ModalConditions() {
  
  // State Variable
  const [open, setOpen] = useState(false);

  // Setting up initial render 
  useEffect(() => {
    let visited = localStorage["MDUTranslateVisited"];
    
    if (visited) {
      setOpen(false)
    } else {
      localStorage["MDUTranslateVisited"] = true;
      setOpen(true)
    }
  }, []);
  
  
  // *************************************************************************
  // Handle events

  const handleClickOpen = async () => {
    setOpen(true)
  };
  
  const handleClickClose = () => {
    setOpen(false)
  };

  // *************************************************************************
  // Build Components

  return (
    <div className="footerLinks">
      <Typography onClick={handleClickOpen}>
        {modalTitle}
      </Typography>
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="modal-title"
        open={open}
      >
        <DialogTitle id="modal-title" className="modalTitle">
          {modalTitle}
        </DialogTitle>
        <DialogContent className="modalContent">
          <div align="left">
            <h4>Before using Translate</h4>
            <p>Translate is available for global Lilly employees to use to translate company documents into a number of available languages.</p>
            <p>Translate is currently approved for red data and there are no restrictions as to the types of documents that can be translated, but good business practices must be observed when using this translation service.</p>
            <p>Be knowledgeable of the type of content you are uploading to Translate, especially if it has the potential to contain personal information (PI).</p>
            <p>Lilly is committed to safeguarding the personal information entrusted to us.</p>
            <p>Any inappropriate use or disclosure of that PI – intentional or inadvertent – undermines trust and discourages people from sharing that vital information with us.</p>
            <p>As a reminder, we must comply with all legal requirements and company policies that apply to the collection, use, and retention of PI.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
