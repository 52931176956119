import API from "../API";


const getSpeechVoices = async () => {
  // URL for Supported translation languages
  // TODO: Fix automatic selection pulling from Azure
  try {
    const tokenURL = 'https://centralus.api.cognitive.microsoft.com/sts/v1.0/issuetoken';
    let tokenHeaders = new Headers();
    tokenHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    tokenHeaders.append("Content-type", "application/x-www-form-urlencoded");
    tokenHeaders.append("Content-Length", "0");

    let tokenRequestOptions = {
      method: 'POST',
      headers: tokenHeaders
    }

    const tokenResponse = await fetch(tokenURL, tokenRequestOptions)
    console.log(await tokenResponse.json())


    const URL = "https://centralus.tts.speech.microsoft.com/cognitiveservices/voices/list";
    let accessToken = "Bearer " + await fetch(tokenURL, tokenRequestOptions);
    let myHeaders = new Headers();
    
    // myHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    myHeaders.append("Authorization", accessToken);
    
    let requestOptions = {
      method : 'GET',
      headers: myHeaders,
      signal: AbortSignal.timeout(500)
    }

    const response = await fetch(URL,requestOptions)
    const resJson = await response.json()

    console.log(resJson)

    return resJson;
  }
  catch (e){
    console.error(e.message)
    console.log("Couldnt fetch voices here is a list of predefined ones")
    let textToSpeechData = [
      {
        locale: "",
        language: "",
        voices: [
          "",
          "",
        ],
      },
      {
        locale: "zh-CN",
        language: "Chinese (Mandarin, Simplified)",
        voices: [
          "zh-CN-XiaoxiaoNeural (Female)",
          "zh-CN-YunxiNeural (Male)",
          "zh-CN-YunjianNeural (Male)",
          "zh-CN-XiaoyiNeural (Female)",
          "zh-CN-YunyangNeural (Male)",
          "zh-CN-XiaochenNeural (Female)",
          "zh-CN-XiaohanNeural (Female)",
          "zh-CN-XiaomengNeural (Female)",
          "zh-CN-XiaomoNeural (Female)",
          "zh-CN-XiaoqiuNeural (Female)",
          "zh-CN-XiaoruiNeural (Female)",
          "zh-CN-XiaoshuangNeural (Female)",
          "zh-CN-XiaoxuanNeural (Female)",
          "zh-CN-XiaoyanNeural (Female)",
          "zh-CN-XiaoyouNeural (Female)",
          "zh-CN-XiaozhenNeural (Female)",
          "zh-CN-YunfengNeural (Male)",
          "zh-CN-YunhaoNeural (Male)",
          "zh-CN-YunxiaNeural (Male)",
          "zh-CN-YunyeNeural (Male)",
          "zh-CN-YunzeNeural (Male)",
        ],
      },
      {
        locale: "cs-CZ",
        language: "Czech (Czech Republic)",
        voices: [
          "cs-CZ-VlastaNeural (Female)",
          "cs-CZ-AntoninNeural (Male)",
        ],
      },
      {
        locale: "en-GB",
        language: "English (United Kingdom)",
        voices: [
          "en-GB-SoniaNeural (Female)",
          "en-GB-RyanNeural (Male)",
          "en-GB-LibbyNeural (Female)",
          "en-GB-AbbiNeural (Female)",
          "en-GB-AlfieNeural (Male)",
          "en-GB-BellaNeural (Female)",
          "en-GB-ElliotNeural (Male)",
          "en-GB-EthanNeural (Male)",
          "en-GB-HollieNeural (Female)",
          "en-GB-MaisieNeural (Female)",
          "en-GB-NoahNeural (Male)",
          "en-GB-OliverNeural (Male)",
          "en-GB-OliviaNeural (Female)",
          "en-GB-ThomasNeural (Male)",
        ],
      },
      {
        locale: "en-US",
        language: "English (United States)",
        voices: [
          "en-US-GuyNeural (Male)",
          "en-US-JennyNeural (Female)",
          "en-US-AriaNeural (Female)",
          "en-US-DavisNeural (Male)",
          "en-US-AmberNeural (Female)",
          "en-US-AnaNeural (Female)",
          "en-US-AndrewNeural (Male)",
          "en-US-AshleyNeural (Female)",
          "en-US-BrandonNeural (Male)",
          "en-US-BrianNeural (Male)",
          "en-US-ChristopherNeural (Male)",
          "en-US-CoraNeural (Female)",
          "en-US-ElizabethNeural (Female)",
          "en-US-EmmaNeural (Female)",
          "en-US-EricNeural (Male)",
          "en-US-JacobNeural (Male)",
          "en-US-JaneNeural (Female)",
          "en-US-JasonNeural (Male)",
          "en-US-MichelleNeural (Female)",
          "en-US-MonicaNeural (Female)",
          "en-US-NancyNeural (Female)",
          "en-US-RogerNeural (Male)",
          "en-US-SaraNeural (Female)",
          "en-US-SteffanNeural (Male)",
          "en-US-TonyNeural (Male)",
        ],
      },
      {
        locale: "fr-FR",
        language: "French (France)",
        voices: [
          "fr-FR-DeniseNeural (Female)",
          "fr-FR-HenriNeural (Male)",
          "fr-FR-AlainNeural (Male)",
          "fr-FR-BrigitteNeural (Female)",
          "fr-FR-CelesteNeural (Female)",
          "fr-FR-ClaudeNeural (Male)",
          "fr-FR-CoralieNeural (Female)",
          "fr-FR-EloiseNeural (Female)",
          "fr-FR-JacquelineNeural (Female)",
          "fr-FR-JeromeNeural (Male)",
          "fr-FR-JosephineNeural (Female)",
          "fr-FR-MauriceNeural (Male)",
          "fr-FR-VivienneNeural (Female)",
          "fr-FR-YvesNeural (Male)",
          "fr-FR-YvetteNeural (Female)",
        ],
      },
      {
        locale: "de-DE",
        language: "German (Germany)",
        voices: [
          "de-DE-ConradNeural (Male)",
          "de-DE-KatjaNeural (Female)",
          "de-DE-AmalaNeural (Female)",
          "de-DE-BerndNeural (Male)",
          "de-DE-ChristophNeural (Male)",
          "de-DE-ElkeNeural (Female)",
          "de-DE-GiselaNeural (Female)",
          "de-DE-KasperNeural (Male)",
          "de-DE-KillianNeural (Male)",
          "de-DE-KlarissaNeural (Female)",
          "de-DE-KlausNeural (Male)",
          "de-DE-LouisaNeural (Female)",
          "de-DE-MajaNeural (Female)",
          "de-DE-RalfNeural (Male)",
          "de-DE-SeraphinaNeural (Female)",
          "de-DE-TanjaNeural (Female)",

        ],
      },
      {
        locale: "el-GR",
        language: "Greek (Greece)",
        voices: [
          "el-GR-AthinaNeural (Female)",
          "el-GR-NestorasNeural (Male)",
        ],
      },
      {
        locale: "hu-HU",
        language: "Hungarian (Hungary)",
        voices: [
          "hu-HU-TamasNeural (Male)",
          "hu-HU-NoemiNeural (Female)",
        ],
      },
      {
        locale: "hi-IN",
        language: "Hindi (India)",
        voices: [
          "hi-IN-SwaraNeural (Female)",
          "hi-IN-MadhurNeural (Male)",
        ],
      },
      {
        locale: "it-IT",
        language: "Italian (Italy)",
        voices: [
          "it-IT-ElsaNeural (Female)",
          "it-IT-DiegoNeural (Male)",
          "it-IT-IsabellaNeural (Female)",
          "it-IT-BenignoNeural (Male)",
          "it-IT-CalimeroNeural (Male)",
          "it-IT-CataldoNeural (Male)",
          "it-IT-FabiolaNeural (Female)",
          "it-IT-FiammaNeural (Female)",
          "it-IT-GianniNeural (Male)",
          "it-IT-GiuseppeNeural (Male)",
          "it-IT-ImeldaNeural (Female)",
          "it-IT-IrmaNeural (Female)",
          "it-IT-LisandroNeural (Male)",
          "it-IT-PalmiraNeural (Female)",
          "it-IT-PierinaNeural (Female)",
          "it-IT-RinaldoNeural (Male)",
        ],
      },
      {
        locale: "ja-JP",
        language: "Japanese (Japan)",
        voices: [
          "ja-JP-NanamiNeural (Female)",
          "ja-JP-KeitaNeural (Male)",
          "ja-JP-AoiNeural (Female)",
          "ja-JP-DaichiNeural (Male)",
          "ja-JP-MayuNeural (Female)",
          "ja-JP-NaokiNeural (Male)",
          "ja-JP-ShioriNeural (Female)",
        ],
      },
      {
        locale: "ko-KR",
        language: "Korean (Korea)",
        voices: [
          "ko-KR-SunHiNeural (Female)",
          "ko-KR-InJoonNeural (Male)",
          "ko-KR-BongJinNeural (Male)",
          "ko-KR-GookMinNeural (Male)",
          "ko-KR-HyunsuNeural (Male)",
          "ko-KR-JiMinNeural (Female)",
          "ko-KR-SeoHyeonNeural (Female)",
          "ko-KR-SoonBokNeural (Female)",
          "ko-KR-YuJinNeural (Female)",

        ],
      },
      {
        locale: "pl-PL",
        language: "Polish (Poland)",
        voices: [
          "pl-PL-AgnieszkaNeural (Female)",
          "pl-PL-MarekNeural (Male)",
          "pl-PL-ZofiaNeural (Female)",
        ],
      },
      {
        locale: "pt-BR",
        language: "Portuguese (Brazil)",
        voices: [
          "pt-BR-FranciscaNeural (Female)",
          "pt-BR-AntonioNeural (Male)",
          "pt-BR-BrendaNeural (Female)",
          "pt-BR-DonatoNeural (Male)",
          "pt-BR-ElzaNeural (Female)",
          "pt-BR-FabioNeural (Male)",
          "pt-BR-GiovannaNeural (Female)",
          "pt-BR-HumbertoNeural (Male)",
          "pt-BR-JulioNeural (Male)",
          "pt-BR-LeilaNeural (Female)",
          "pt-BR-LeticiaNeural (Female)",
          "pt-BR-ManuelaNeural (Female)",
          "pt-BR-NicolauNeural (Male)",
          "pt-BR-ThalitaNeural (Female)",
          "pt-BR-ValerioNeural (Male)",
          "pt-BR-YaraNeural (Female)",
        ],
      },
      {
        locale: "pt-PT",
        language: "Portuguese (Portugal)",
        voices: [
          "pt-PT-RaquelNeural (Female)",
          "pt-PT-DuarteNeural (Male)",
          "pt-PT-FernandaNeural (Female)",
        ],
      },
      {
        locale: "sk-SK",
        language: "Slovak (Slovakia)",
        voices: [
          "sk-SK-ViktoriaNeural (Female)",
          "sk-SK-LukasNeural (Male)",
        ],
      },
      {
        locale: "es-MX",
        language: "Spanish (Mexico)",
        voices: [
          "es-MX-JorgeNeural (Male)",
          "es-MX-DaliaNeural (Female)",
          "es-MX-BeatrizNeural (Female)",
          "es-MX-CandelaNeural (Female)",
          "es-MX-CarlotaNeural (Female)",
          "es-MX-CecilioNeural (Male)",
          "es-MX-GerardoNeural (Male)",
          "es-MX-LarissaNeural (Female)",
          "es-MX-LibertoNeural (Male)",
          "es-MX-LucianoNeural (Male)",
          "es-MX-MarinaNeural (Female)",
          "es-MX-NuriaNeural (Female)",
          "es-MX-PelayoNeural (Male)",
          "es-MX-RenataNeural (Female)",
          "es-MX-YagoNeural (Male)",
        ],
      },
      {
        locale: "es-ES",
        language: "Spanish (Spain)",
        voices: [
          "es-ES-AlvaroNeural (Male)",
          "es-ES-ElviraNeural (Female)",
          "es-ES-AbrilNeural (Female)",
          "es-ES-ArnauNeural (Male)",
          "es-ES-DarioNeural (Male)",
          "es-ES-EliasNeural (Male)",
          "es-ES-EstrellaNeural (Female)",
          "es-ES-IreneNeural (Female)",
          "es-ES-LaiaNeural (Female)",
          "es-ES-LiaNeural (Female)",
          "es-ES-NilNeural (Male)",
          "es-ES-SaulNeural (Male)",
          "es-ES-TeoNeural (Male)",
          "es-ES-TrianaNeural (Female)",
          "es-ES-VeraNeural (Female)",
          "es-ES-XimenaNeural (Female)",
        ],
      },
      {
        locale: "tr-TR",
        language: "Turkish (Turkey)",
        voices: [
          "tr-TR-EmelNeural (Female)",
          "tr-TR-AhmetNeural (Male)",
        ],
      },
    ]

    for (var i = 0; i < textToSpeechData.length; i++) { 
      
      for (var j = 0; j < textToSpeechData[i].voices.length; j++) {
        let name_gender = textToSpeechData[i].voices[j].split(" ")
        let name_split = name_gender[0].split("-")
        let newObj = {
          name: name_split[2],
          gender: name_gender[1]
        }
        textToSpeechData[i].voices[j] = newObj
      }
      let emptyEntry = {
        name: "",
        gender: ""
      }
      textToSpeechData[i].voices.unshift(emptyEntry) 
    }
    return textToSpeechData
  }
}


export default getSpeechVoices;

// Format = [
//   {
//     locale: "et-EE",
//     language: "Estonian (Estonia)",
//     voices: [
//       {
//         name: "",
//         gender: ""
//       }
//       {
//         name: "AnuNeural",
//         gender: "(Female)"
//       },
//       {
//         name: "EduardNeural",
//         gender: "(Male)"
//       }
//     ],
//   },
// ]
