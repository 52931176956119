import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";

// *************************************************************************
// Overall Settings

const modalTitle = "Contact Us";

export default function ModalContact () {
    
  // State Variable
  const [open, setOpen] = useState(false);

  // *************************************************************************
  // Handle events

  const handleClickOpen = async () => {
    setOpen(true)
  };
  
  const handleClickClose = () => {
    setOpen(false)
  };

  // *************************************************************************
  // Build Components

  return (
    <div className="footerLinks">
      <Typography onClick={handleClickOpen}>
        {modalTitle}
      </Typography>
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="modal-title"
        open={open}
      >
        <DialogTitle id="modal-title" className="modalTitle">
          {modalTitle}
        </DialogTitle>
        <DialogContent className="modalContent">
          <div align="left">
            <p>Please do contact us at <strong><a href="mailto:LillyTranslate@lilly.com">LillyTranslate@lilly.com.</a></strong></p>
            <p>We are very interested in any feedback you may have and would especially love to hear about any additional features or ideas you may have.</p>
            <p>Keep up with all things Translation at Lilly on Viva Engage! <strong><a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNDg4NzQ1MTAzMzYifQ">Join the Lilly Translate Community!</a></strong></p>
            <p>Thank you for your support!</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
