import React, {
  Fragment,
  useState,
  useEffect
} from "react";
import {
  isMobile
} from "react-device-detect";
import Lottie from 'react-lottie';
import uuid from 'uuid';
import * as clipboard from "clipboard-polyfill"
import {
  Button,
  Box,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Card,
  CardContent,
  Switch,
  Tooltip,
  Zoom,
  Slider
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import "../styles/App.css";
import S3Encoding from "../utils/S3Encoding";
import aniData from "../data/ani-cloud.json";
import summarize from "../data/summarize-data";
import getSentimentLangs from "../data/sentiment-data";
import API from '../API';
import { useHistory } from "react-router-dom";
// import cciPairs from "../data/pairs-cci"
// MSAL Imports
import { useAccount, useMsal } from "@azure/msal-react";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// *************************************************************************
// Overall Settings

// Translation limit settings
const textMax = 125000;

// Timing settings
const intervalInMs = 1000; // prod should be 1000
const intervalInMsDrop0 = 2000; // prod should be 2000
const intervalInMsDrop1 = 5000; // prod should be 5000
//const intervalInMsPlus0 = 10000; // prod should be 10000 // keep in sync
//const intervalInMSPlus1 = 50000; // prod should be 50000 // keep in sync
//const intervalInMSPlus2 = 60000; // prod should be 60000 // keep in sync
const maxInMs = 900000; // prod should be 300000
const maxInMsDeltaDrop0 = 30000; // prod should be 10000
const maxInMsDeltaDrop1 = 180000; // prod should be 60000
const maxInMsDeltaDrop2 = 360000; // prod should be 120000
const maxInMsDeltaDrop3 = 540000; // prod should be 180000
const maxInMsDeltaDrop4 = 720000; // prod should be 240000

// UI settings
const textTab = 0;
const docTab = 1;
const resultTab = 2;
const sumTab = 0;
const sentTab = 1;
const toLabelDefault = "Text Analysis Results";
const toLabelError = "Text Analysis Challenge";
const toLabelProgress = "In Progress";
const toLabelGeneration = "Generation";

// Animation settings
const aniOptions = {
  loop: true,
  autoplay: true,
  animationData: aniData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Transition settings
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// *************************************************************************
// Overall Error Messages
// If these are added/deleted/changed than the guide-support needs to be updated

const errorMsgDefault = "Uh oh! We faced an unexpected error. [default]\n\nFirst, please checkout out our Tips & FAQs - If you are still unsure, please contact us\n\nIf you are able to share the document you are trying to convert, it may help us to resolve the error. If you cannot, please let us know what criteria you are using and a description of what you are trying to convert. Thank you!";
// Stage 1 should support PDF, so remove msg when transition is done
const errorMsgTime = "Uh oh! We faced an unexpected error. [time]\n\nPlease contact us \n\nIt appears we ran out of time for your request. This usually means that there was more content than we could handle at this time. The amount of content is related to the total number of characters and complexity. It is not related to the file size. We recommend trying to reduce how much content is in the document or text and then trying again. For example, a .docx file can be split in half or a .xlsx file could be converted with fewer sheets. Please let us know about this challenge so we can learn from it and improve our performance. Thank you!";
const errorMsgText = "Uh oh! We faced an unexpected error. [text]\n\nPlease contact us \n\nIt appears you are trying to convert more text than we can currently support. Currently we can only support a maximum length of 125,000 characters. Please try removing some content from your text and try again. Additionally, at the top of the text area there is label that looks simliar to 'Text to Analyze (123/5000)'. The '123' in this example tells you how many characters you have used so far. If you are still having a challenge with the text, please contact us with the text you are trying to convert. Thank you!"
const errorMsgFileZero = "Uh oh! We faced an unexpected error. [zero]\n\nPlease contact \n\nIt appears your request was with an empty file with a size of 0 KB. Please use a file that has content inside of it. If you are still having a challenge with the file, please contact us with the file you are trying to convert. Thank you!";
const errorMsgName = "Uh oh! We faced an unexpected error. [name]\n\nPlease contact us \n\nIt appears you are trying to upload a file that has a file name with characters we do not support yet. Try renaming the file and uploading again. If you are still having a challenge with the file, please contact us with the name of the file you are trying to convert. Thank you!";
const errorMsg422 = "Uh oh! We faced an unexpected error. [422]\n\nPlease contact us \n\nIf you are able to share the document or text you are trying to convert, it may help us to resolve the error. If you cannot, please let us know what criteria you are using and a description of what you are trying to convert. Thank you!";
const errorMsgCopy = "Uh oh! We faced an unexpected error. [copy]\n\nPlease contact us \n\nIt appears copying to the clipboard is not compatible with your browser version. Please contact us with your browser version and the type of mobile device if applicable. Thank you!";


export default function TextAnalysis(){
  
  const history = useHistory();

  // State Variables
  const [userID, setUserID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userCountry, setUserCountry] = useState("");

  const [UIDcaptured, setUIDcaptured] = useState(false);

  const [language, setLanguage] = useState("");

  // Not used in the current PROD release but leaving built into the source code for easy enablement in the future
  const [sentenceCount, setSentenceCounter] = useState();
  const [sentenceCountList, setSentenceCountList] = useState([]);

  const [fromText, setFromText] = useState("");
  const [fromLabel, setFromLabel] = useState(`Text to Analyze (0/${textMax})`);
  const [fromFileName, setFromFileName] = useState("");
  const [fromFileExt,setFromFileExt] = useState("");
  const [toFileExt, setToFileExt] = useState("txt"); // Limiting to TXT files for now
  const [fromFile, setFromFile] = useState(null);
  const [fromSumFileExtAllowed, setfromSumFileExtAllowed] = useState(["txt", "pdf", "docx"])
  const [fromSentFileExtAllowed, setfromSentFileExtAllowed] = useState(["txt", "pdf", "docx"])

  const [uploadKey, setUploadKey] = useState("");
  const [downloadKey, setDownloadKey] = useState("");
  const [downloadFailKey, setDownloadFailKey] = useState("");

  const [toText, setToText] = useState("");
  const [toLabel, setToLabel] = useState(toLabelDefault);
  const [toFileUrl, setToFileUrl] = useState("");
  const [toFailFileUrl, setToFailFileUrl] = useState("");

  const [outFn, setOutFn] = useState("");
  
  const [disableTextProcessing, setDisableTextProcessing] = useState(true);
  const [disableTextCopy, setDisableTextCopy] = useState(true);
  const [disableTextFullScreen, setDisableTextFullScreen] = useState(true);
  const [disableDocProcessing, setDisableDocProcessing] = useState(true);
  const [disableDownload, setDisableDownload] = useState(true);
  const [disableDocUpload, setDisableDocUpload] = useState(false);

  const [disableTTS, setDisableTTS] = useState(false);
  const [disableSTT, setDisableSTT] = useState(false);

  const [showDownload, setShowDownload] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  const [contentTab, setContentTab] = useState(textTab);
  const [analysisTab, setAnalysisTab] = useState(0);
  const [activeJob, setactiveJob] = useState(false);
  const [activeJobID, setactiveJobID] = useState("");
  const [activeJobTime, setactiveJobTime] = useState(null);

  const [taTitle, setTaTitle] = useState("Text Summarization");
  const [textPage, setTextPage] = useState("Text");
  const [docPage, setDocPage] = useState("Document");

  // Arrays contains all Language Pairs
  const [azureSumLanguages, setAzureSumLanguages] = useState([])
  const [azureSentLanguages, setAzureSentLanguages] = useState([])

  // MSAL STATE HOOKS
  // fetch our Public Client Application instance
  const {instance} = useMsal();
  // Fetch current Active Account
  const activeAccount = useAccount()

  // To avoid user scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
  }, []);
  
  // Called whenever activeAccount changes
  useEffect(() => {

    summarize.getSummarizeLangs().then((res) => {
      setAzureSumLanguages(res)
    })

    summarize.getSummarizeSentenceCount().then((res) => {
      setSentenceCountList(res)
    })

    getSentimentLangs().then((res) => {
      setAzureSentLanguages(res)
    })

    // ID Handling for CA accounts using Cognitive AI
    if (String(activeAccount.username).includes("-CA")) {
      setUserID(String(String(activeAccount.username).split('-CA')[0]));
    }
    else {
      setUserID(activeAccount.idTokenClaims.employee_id);
    }
    
    setUserEmail(activeAccount.username);
    setUserFullName(activeAccount.name);

    // console.log(activeAccount.idTokenClaims.employee_id);
    // console.log(activeAccount.username);
    // console.log(activeAccount.name);
    // console.log("ABOVE IS THE ACTIVE ACCOUNT INFO FOR LOGGING");

    // Get user country info
    try {
      fetch("https://ipapi.co/country_name")
        .then(res => res.text())
        .then(
          (result) => {
            setUserCountry(result);
          },
          (error) => {
            console.error(error);
            setUserCountry("LillyDefault");
          }
        )
    } catch (e) {
      // ignore this error if it occurs
      console.log("Country Fetch error: " + e.message);
    }
  }, [activeAccount])


  // Called whenever userID actually gets updated and can be sent through for API calls
  useEffect(() => {
    setUIDcaptured(true);
    
  }, [userID])

  // Called when one of the dependencies change
  useEffect(() => {
    handleValidateTabs();
  }, [language, sentenceCount, contentTab, fromFileName, fromFileExt, fromFile, UIDcaptured]);
  
  useEffect(() => {
    handleValidateTabsDelayed();
  }, [fromText, fromLabel]);


  // *************************************************************************
  // Handle events

  // Populate drop downs
  const handleGenerateLanguageOptions = (opts) => {
    return opts.map(opt => {
      return ( <option value={opt.code} key={opt.key}> {opt.name} </option>);
    });
  }

  // Check conditions to see if convert button is pressable
  const handleValidateTabs = () => {
    
    // Check to see which speech service we are using and validate accordingly
    if (analysisTab === sumTab) {
      var validBoolText = language && fromText && fromText.trim() && (activeJob === false) && (UIDcaptured === true); // No need to validate file extension since we create the temporary txt file ourselves
      var validBoolDoc = language && fromFileName && (activeJob === false) && (UIDcaptured === true) && (fromSumFileExtAllowed.includes(fromFileExt));
      
      // Uncomment below and remove above to start using sentenceCount:
      // --------------------------------------------------------------
      // var validBoolText = language && sentenceCount && fromText && fromText.trim() && (activeJob === false) && (UIDcaptured === true); // No need to validate file extension since we create the temporary txt file ourselves
      // var validBoolDoc = language && sentenceCount && fromFileName && (activeJob === false) && (UIDcaptured === true) && (fromSumFileExtAllowed.includes(fromFileExt));
    }
    else if (analysisTab === sentTab) {
      var validBoolText = language && fromText && fromText.trim() && (activeJob === false) && (UIDcaptured === true); // No need to validate file extension since we create the temporary txt file ourselves
      var validBoolDoc = language && fromFileName && (activeJob === false) && (UIDcaptured === true) && (fromSentFileExtAllowed.includes(fromFileExt));
    }
    else {
      console.error("Somehow a value for analysisTab that shouldn't be possible has been set: ", analysisTab)
      console.error("Forcing buttons to be disabled until new tab choice is selected.")

      var validBoolText = false;
      var validBoolDoc = true;
    }
    
    const enableTextConvertButtonCondition = validBoolText;
    const enableDocConvertButtonCondition = validBoolDoc;
    
    
    if (contentTab === textTab) {
      // checking conditions to decide if button is to be pressable
      if (enableTextConvertButtonCondition) {
        setDisableTextProcessing(false);
      }  
      else {
        setDisableTextProcessing(true);
      }

    } 
    else if (contentTab === docTab) {
      
      if (activeJob === false) {
        setDisableDocUpload(false);
      } 
      else {
        setDisableDocUpload(true);
      }
      // checking conditions to decide if button is to be pressable
      if (enableDocConvertButtonCondition) {
        setDisableDocProcessing(false);
      } 
      else {
        setDisableDocProcessing(true);
      }
            
      if (activeJob === false) {
        setDisableDocUpload(false);
      } 
      else {
        setDisableDocUpload(true);
      }
      // checking conditions to decide if button is to be pressable
      if (enableDocConvertButtonCondition) {
        setDisableDocProcessing(false);
      } 
      else {
        setDisableDocProcessing(true);
      }
    }
  }

  const handleValidateTabsDelayed = () => {
    setTimeout(handleValidateTabs, 1000);
  }

  const handleResetCriteria = () => {
    setLanguage("");
  }
  
  const handleResetDocTab = () => {
    setFromFileName("");
    setFromFileExt("");
    setFromFile(null);
    setUploadKey("");
    setDownloadKey("");
    setDownloadFailKey("");
  }

  const handleResetResultTab = () => {
    setToText("");
    setToLabel(toLabelDefault);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDownload(true);
    setDisableTextProcessing(true);
    setDisableDocProcessing(true);
    setShowDownload(false);
  }

  // Handling a selection from the dropdowns
  const handleChangeLangChoice = event => {
    setLanguage(event.target.value);
  }

  const handleChangeSentenceCount = event => {
    setSentenceCounter(event.target.value);
  }

  const handleGenerateSentenceCount = (opts) => {
    return opts.map(opt => {
      return ( <option value={opt.display}> {opt.display} </option>);
    });
  }

  const handleChangeTabChoice = (event, newValue) => {
    setContentTab(newValue);
    handleScrollBottom();
  }

  const handleChangeAnalysisTabChoice = (event, newValue) => {
    if (newValue === sumTab) {
      setTaTitle("Text Summarization");
      handleResetDocTab();  // Reset uploaded file when the speech jobType tab changes
    }
    else if (newValue === sentTab){
      setTaTitle("Sentiment Analysis");
      handleResetDocTab();  // Reset uploaded file when the speech jobType tab changes
    }
    else {
      console.error("A value for analysis tab that should not be possible has been chosen, we will refresh the page to fix this: ", newValue);
      window.location.reload();
    }

    setAnalysisTab(newValue);
    handleResetCriteria();
    setLanguage("");
    handleScrollBottom();
  }
  
  const handleChangeFromText = event => {
    setFromText(event.target.value);
    setFromLabel(`Text to Analyze (${event.target.value.length}/${textMax})`);
  }

  const handleChangeToText = event => {
    setToText(event.target.value);
  }

  const handleChangeUpload = event => {
    const curFile = event.target.files[0];
    if (curFile === undefined) {
      // handleError(null, errorMsgDefault);
      return;
    }

    // Filter for files that are empty
    if (curFile.size === 0) {
      handleError(null, errorMsgFileZero);
      return;
    }

    // TODO: Add filtering mechanism for the maximum number of characters allowed in a txt file (125,000)

    let fileExt = curFile.name.split('.').pop().toLowerCase();

    if (S3Encoding.isASCII(curFile.name) === false) {
      // Remove Non-ASCII characters
      var clean_name = curFile.name.replace(/[\u{0080}-\u{FFFF}]/gu, "");

      // If the filename is only made up of ASCII characters we are going to have to set a new filename for them based on their global ID
      if (clean_name.split('.')[0].length === 0) {
        var uique_id = uuid.v4();
        clean_name = "file_" + uique_id + "." + fileExt;
      }
      
      setFromFileName(clean_name);
    }
    else {
      setFromFileName(curFile.name);
    }

    setFromFileExt(fileExt);
    setFromFile(curFile);
  }

  const handleClickConvert = event => {
    handleTimer(true);

    handleResetResultTab();
    
    setactiveJob(true);

    if (contentTab === textTab) {
      // This is done outside the validation function for performance while typing
      if (fromText.length > textMax) {
        handleError(null, errorMsgText);
      } 
      else {
        handleText();
      }
    } 
    else if (contentTab === docTab) {
      handleDocs();
    }
  }

  const handleClickCopy = event => {
    try {
      clipboard.writeText(toText);
    } 
    catch (error) {
      handleError(error, errorMsgCopy);
    }
  }

  const handleClickDownload = event => {
    fetch(toFileUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = String(outFn)
        document.body.appendChild(a);
        if(a.download !== "null")
        {
            a.click();
            // alert('Your Translated file ' + a.download + ' has downloaded!');
        }
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Could not download file.'));

    // *** OG CODE ***
    // ***************
    // if (window.open) {
      // window.open(toFileUrl, '_blank').focus();
    // } else {
      //window.location = toFileUrl;
    // }
  }

  const handleClickCancel = event => {
    window.location.reload();
  }

  const handleCloseFullText = event => {
    setShowFullText(false);
  }

  const handleError = (error, displayMsg) => {
    console.log("** ERROR OCCURED **");
    if (error) {
      if (error.message) {
        console.log(error.message);
      } 
      else {
        console.log(error);
      }
    }

    let errorText = "";
    if (displayMsg) {
      errorText = displayMsg;
    } else {
      errorText = errorMsgDefault;
    }

    setToLabel(toLabelError);
    setToText(errorText);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDownload(true);
    setContentTab(resultTab);
    setactiveJob(false);
    setactiveJobID("");

    handleResetDocTab();    
    handleTimer(false);
    handleScrollBottom();
  }

  const handleTranslateID = () => {
    var translationUUID = uuid.v4();
    setactiveJobID(translationUUID);

    return translationUUID;
  }

  const handleTimer = (restartTimer) => {
    if (process.env.REACT_APP_TIMER === "on") {
      if (restartTimer) {
        setactiveJobTime(Date.now());
      } 
      else {
        let dateDiffSec = ((Date.now() - this.state.activeJobTime) / 1000).toFixed(3);
        let dateDiffMin = (dateDiffSec / 60).toFixed(3);
        setactiveJobTime(`sec:${dateDiffSec}; min:${dateDiffMin}`);
      }
    }
  }
  
  const handleScrollBottom = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }

  // *************************************************************************
  // Handle Text

  const handleText = async () => {
    try {
      textUpdates(0);
      let tID = handleTranslateID();

      // Create a temporary txt file in the client-side browser local storage that we will use to submit the TTS job
      var fn = String(userID + "_" + String(tID) + "_input")
      sessionStorage.setItem(String(fn + ".txt"), String(fromText))

      let keys = createTextKeys(fn);
      
      // make a normal file API call
      let URLs = await API.submitTextAnalysis(sessionStorage.getItem(String(fn + ".txt")), String(keys[0]), String(keys[1]), String(keys[2]));

      // Delete the temporary txt file from the local storage
      sessionStorage.removeItem(String(fn + ".txt"))

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      textUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createTextKeys = (fn) => {
    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes

    if (analysisTab === sumTab) {
      var jobType = "summarize";
    }
    else if (analysisTab === sentTab) {
      var jobType = "sentiment";
    }
    else {
      console.error("A value for analysis tab that should not be possible has been chosen, we will refresh the page to fix this: ", analysisTab);
      window.location.reload();
    }

    setOutFn(fn);

    let uKey = `private/UI/${jobType}/${userID}/${language}/${fn}.txt`
    let downloadFailKey = `${uKey}_FAIL.json`;
    let dKey = `private/UI/${jobType}/${userID}/${language}/${fn}.${toFileExt}`

    return [uKey, downloadFailKey, dKey];
  }

  const textUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your text analysis job is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading text ${data}%]\n\n`;
    let msg2 = `${msg0}Text uploaded\n\n`;
    let msg3 = `${msg2}[Analyzing text...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here analyzing...`;
    let msg5 = `${msg3}... not to worry, this can take up to 15 minutes for larger texts...`;
    let msg6 = `${msg3}... we are good, doing lots of analyzing...`;
    let msg7 = `${msg3}... no errors, still analyzing...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour results are ready! Please download it by clicking the button below.\n\nThank you for using Lilly Translate :)`;
    let msg10 = `[ ** COMPLETE ** ]\n\nYour results are ready! Please download it by clicking the button below.\n\nThank you for using our Lilly Translate :)`;

    switch (step) {
      case 0:
        setShowDownload(true);
        setDisableDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        textUpdates(3);

        break;
      case 3:
        setToText(msg3);

        // console.log(toFileUrl);
        // console.log(toFailFileUrl);
        // console.log('testing');

        let dropStatus = [false, false, false, false, false];
        // continuousPollForText(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForText(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        if (fromFileExt === "txt") {
          setToText(msg9);
        } 
        else {
          setToText(msg10);
        }
        setToLabel(toLabelGeneration);
        setDisableDownload(false);
        setactiveJob(false);
        setactiveJobID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

  // *************************************************************************
  // Handle Document to Speech

  const handleDocs = async () => {
    try {
      docUpdates(0);
      let tID = handleTranslateID();
      let keys = createKeys(tID);
      
      // make a normal file API call
      let URLs = await API.submitTextAnalysis(fromFile, String(keys[0]), String(keys[1]), String(keys[2]));

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      docUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createKeys = (tID) => {
    // REMOVE THIS FROM 5.1 once UI file handling lambda uses metdata instead of paths
    let blankedFromFileName = S3Encoding.removeSpecials(fromFileName);
    let prefixBlankedFromFileName = blankedFromFileName.substr(0, blankedFromFileName.lastIndexOf("."));
    prefixBlankedFromFileName = prefixBlankedFromFileName.split(".").join("_");
    tID = prefixBlankedFromFileName; //`${prefixBlankedFromFileName}.${this.state.fromFileExt}`;
    // REMOVE ABOVE

    if (analysisTab === sumTab) {
      var jobType = "summarize";
    }
    else if (analysisTab === sentTab) {
      var jobType = "sentiment";
    }
    else {
      console.error("A value for analysis tab that should not be possible has been chosen, we will refresh the page to fix this: ", analysisTab);
      window.location.reload();
    }

    setOutFn(tID);

    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes

    let uKey = `private/UI/${jobType}/${userID}/${language}/${tID}.${fromFileExt}`
    let downloadFailKey = `${uKey}_FAIL.json`;
    let dKey = `private/UI/${jobType}/${userID}/${language}/${tID}.${toFileExt}`

    return [uKey, downloadFailKey, dKey];
  }

  const docUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your document analysis job is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading document ${data}%]\n\n`;
    let msg2 = `${msg0}Document uploaded\n\n`;
    let msg3 = `${msg2}[Analyzing document...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here analyzing...`;
    let msg5 = `${msg3}... not to worry, this can take up to 15 minutes for larger files...`;
    let msg6 = `${msg3}... we are good, doing lots of analyzing...`;
    let msg7 = `${msg3}... no errors, still analyzing...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour results are ready! Please download it by clicking the button below.\n\nThank you for using Lilly Translate :)`;
    let msg10 = `[ ** COMPLETE ** ]\n\nYour results are ready! Please download it by clicking the button below.\n\nThank you for using our Lilly Translate :)`;

    switch (step) {
      case 0:
        setShowDownload(true);
        setDisableDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        docUpdates(3);

        break;
      case 3:
        setToText(msg3);

        // console.log(toFileUrl);
        // console.log(toFailFileUrl);
        // console.log('testing');

        let dropStatus = [false, false, false, false, false];
        // continuousPollForDoc(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForDoc(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        if (fromFileExt === "txt") {
          setToText(msg9);
        } 
        else {
          setToText(msg10);
        }
        setToLabel(toLabelGeneration);
        setDisableDownload(false);
        setactiveJob(false);
        setactiveJobID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

    // The first call to this recursive function should be:
  // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForDoc = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get processed File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get processed Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the processed file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      docUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          docUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForDoc(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  // The first call to this recursive function should be:
  // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForText = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get processed File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get processed Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the processed file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      textUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          textUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForText(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  const verifyUrlStatus = async (url) => {
    var fileStatus = 0;
    var isOK = false;
    fileStatus = await fetch(url)
      .then(res => res.status)
      .catch(error => {
        // ignore these errors if it occurs
        console.log("Fetch error:" + error);
      });

    if ((fileStatus >= 200 && fileStatus < 300) || fileStatus === 302) {
      isOK = true;
    } else {
      isOK = false;
    }

    return isOK;
  }

  return (
    <div>
      <Grid container spacing={1} className="translateGrid">
        <Grid item xs={12} sm={isMobile ? 12 : 4}>
          <Card className="translateCard">
            <CardContent>
              <p className="translateCardTitle" color="inherit">
                {taTitle} Options
              </p>
              <Tabs
                value={analysisTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeAnalysisTabChoice}
                centered
              >
                <Tooltip title="Generate a Summary of Text" placement="top-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab 
                    label="Summarize"
                    disabled={disableTTS}
                  />
                </Tooltip>
                <Tooltip title="Analyze the Emotion of Text" placement="top-end" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab
                    label="Sentiment"
                    disabled={disableSTT}
                  />
                </Tooltip>              
              </Tabs>
              {(activeJob === false) && (analysisTab===sumTab) &&(
                <Fragment>
                  <Grid item xs={12}>
                    <Tooltip title="Language of Text to Analyze" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        select
                        label={"Language of Text"}
                        required
                        value={language}
                        onChange={handleChangeLangChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateLanguageOptions(azureSumLanguages)}
                      </TextField>
                    </Tooltip>
                    {/*
                    <Tooltip title="Number of Sentences" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        select
                        label={"Sentence Count"}
                        required
                        value={sentenceCount}
                        onChange={handleChangeSentenceCount}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateSentenceCount(sentenceCountList)}
                      </TextField>
                    </Tooltip> */}
                    {/* <Typography >
                      Sentence Count
                    </Typography>
                    <Box sx={{width:320, textAlign:'center', display:'inline-block'}}>
                      <Slider
                        size="medium"
                        defaultValue={0}
                        valueLabelDisplay="auto"
                        shiftStep={1}
                        step={1}
                        marks
                        min={0}
                        max={20}
                        /
                      >
                    </Box> */}


                  </Grid>
                  {process.env.REACT_APP_TIMER === "on" && (
                    <p>{activeJobTime}</p>
                  )}
                </Fragment>
              )}
              {(activeJob === false) && (analysisTab===sentTab) && (
                <Fragment>
                  <Grid item xs={12}>                   
                    <Tooltip title="Language of Text to Analyze" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <TextField
                        select
                        label={"Language of Text"}
                        required
                        value={language}
                        onChange={handleChangeLangChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateLanguageOptions(azureSentLanguages)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                </Fragment>
              )}
              {activeJob === true && (
                <Fragment>
                  <Lottie
                    options={aniOptions}
                    height={250}
                    width={250}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={isMobile ? 12 : 8}>
          <Card className="translateCard">
            <CardContent>
              <br></br>
              <Tabs
                value={contentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTabChoice}
              >
                <Tab label={textPage} />
                <Tab label={docPage} />
                <Tab label="Results" />
              </Tabs>
              {(contentTab === textTab) && (analysisTab === sumTab) && (
                <Fragment>
                  <TextField
                    id="contentSource"
                    label={fromLabel}
                    placeholder="Text to Summarize"
                    multiline
                    value={fromText}
                    onChange={handleChangeFromText}
                    rows="8"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableTextProcessing}
                  >
                    Summarize
                  </Button>
                </Fragment>
              )}
              {(contentTab === docTab) && (analysisTab === sumTab) && (
                <Fragment>
                  <label htmlFor="fileUpload">
                    <Typography variant="subtitle1" className="fullTypogUpload">
                      <p><b>Generate Summary from Document</b></p>(Word, PDF or TXT files)<br/><p><i>125,000 Characters MAX</i></p>
                    </Typography>
                    <input
                      type="file"
                      accept=".txt, .pdf, .docx" // .docx
                      onChange={handleChangeUpload}
                      id="fileUpload"
                      className="displayNone"
                      disabled={disableDocUpload}
                    />
                    <Tooltip title="125,000 Characters MAX" placement="right" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Button
                        variant="contained"
                        component="span"
                        disabled={disableDocUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload File
                        <VisuallyHiddenInput type="file" />
                      </Button>
                  </Tooltip>
                    <p>{fromFileName}<br/></p>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableDocProcessing}
                  >
                    Summarize
                  </Button>
                </Fragment>
              )}
              {(contentTab === textTab) && (analysisTab === sentTab) && (
                <Fragment>
                  <TextField
                    id="contentSource"
                    label={fromLabel}
                    placeholder="Text to Analyze Sentiment"
                    multiline
                    value={fromText}
                    onChange={handleChangeFromText}
                    rows="8"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableTextProcessing}
                  >
                    Analyze
                  </Button>
                </Fragment>
              )}
              {(contentTab === docTab) && (analysisTab === sentTab) && (
                <Fragment>
                  <label htmlFor="fileUpload">
                    <Typography variant="subtitle1" className="fullTypogUpload">
                      <p><b>Analyze Sentiment of Document</b></p>(Word, PDF or TXT files)<br/><p><i>125,000 Characters MAX</i></p>
                    </Typography>
                    <input
                      type="file"
                      accept=".txt, .pdf, .docx"
                      onChange={handleChangeUpload}
                      id="fileUpload"
                      className="displayNone"
                      disabled={disableDocUpload}
                    />
                    <Tooltip title="125,000 Characters MAX" placement="right" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Button
                        variant="contained"
                        component="span"
                        disabled={disableDocUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload File
                        <VisuallyHiddenInput type="file" />
                      </Button>
                  </Tooltip>
                    <p>{fromFileName}<br/></p>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableDocProcessing}
                  >
                    Analyze
                  </Button>
                </Fragment>
              )}
              {contentTab === resultTab && (
                <Fragment>
                  <TextField
                    id="contentResult"
                    label={toLabel}
                    placeholder={toLabel}
                    multiline
                    value={toText}
                    minRows="8"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  {showDownload === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickDownload}
                      disabled={disableDownload}
                    >
                      Download
                    </Button>
                  )}
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog fullScreen open={showFullText} onClose={handleCloseFullText} TransitionComponent={Transition}>
        <AppBar className="fullEditAppBar">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseFullText} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="fullEditTitle">
              Full Screen Post-Editing
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickCopy}>
              Copy to Clipboard
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} className="fullEditGrid">
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentSourceFullEdit"
              label="Text to Analyze (locked)"
              placeholder="Text to Analyze"
              multiline
              value={fromText}
              minRows="20"
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentTargetFullEdit"
              label="Translation (edit)"
              placeholder="Converted Text"
              multiline
              value={toText}
              onChange={handleChangeToText}
              minRows="20"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}