import React from "react";
import '../styles/App.css';
import AppBar from "@material-ui/core/AppBar";
import ModalConditions from './ModalConditions';
import ModalContact from './ModalContact';
import ModalAPIKey from './ModalAPIKey';
import ModalFAQ from './ModalFAQ';


export default function Footer(props) {
  return (
    <div>
      <AppBar color="primary" className={props.className}>
        <div className="footerLinksBar">
          <ModalConditions color="primary"/> | <ModalContact color="primary"/> | <ModalAPIKey color="primary"/> | <ModalFAQ color="primary"/> 
        </div>
      </AppBar>
    </div>
  );
}
